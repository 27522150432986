'use client'

import { motion } from 'framer-motion'
import { useInView } from '@/hooks/useInView'
import Image, { StaticImageData } from 'next/image'
import { useRTLAwareStyle } from '@/util/rtl'
import Link from 'next/link'

type Props = {
  title1: string;
  title2: string;
  slogan: string;
  lang: string;
  imageSrc: StaticImageData;
}

export default function Hero({ title1, title2, slogan, lang, imageSrc }: Props) {
  const { ref, isInView } = useInView()
  const letterSpacing = useRTLAwareStyle('tracking-wider', '')

  return (
    <motion.section
      ref={ref}
      initial={{ opacity: 0 }}
      animate={isInView ? { opacity: 1 } : {}}
      transition={{ duration: 0.8, ease: 'easeOut' }}
      className="w-full xl:container mx-auto flex flex-col items-center"
    >
      <div className="relative w-full h-[200px] md:h-[400px] lg:h-[550px] overflow-hidden">
        <motion.div
          initial={{ opacity: 0 }}
          animate={isInView ? { opacity: 1 } : {}}
          transition={{ duration: 0.6, ease: 'easeOut' }}
        >
          <Image
            src={imageSrc}
            alt="Hero image"
            fill
            priority
            className="object-cover"
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
          />
        </motion.div>
      </div>

      <Link 
        href={`/${lang}/mothers-day`}
        className="inline-flex items-center justify-center text-lg font-medium text-[#bcb398] hover:text-[#a39a82] transition-colors font-roboto mt-3"
      >
        {title2}
        <span aria-hidden="true" className="ml-2">&rarr;</span>
      </Link>

      <div className="text-center py-2 md:py-6 bg-white">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6, delay: 0.4, ease: 'easeOut' }}
          className={`${letterSpacing} text-xl md:text-4xl font-bold mb-2 md:mb-4 ${lang === 'ar' ? 'font-arabic' : ''}`}
        >
          {title1}
        </motion.h1>
      </div>
    </motion.section>
  )
}

